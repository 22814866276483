function Header({ schoolId, schoolName, beforeView, theme, changeTheme }) {
    return (
        <header className="container Header">
            <p className="schoolId">{schoolId}</p>
            <p className="schoolName text-center">{schoolName}</p>

            <div className="btn-group Header-Menu">
                <button type="button" className="dropdown-toggle Header-Button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-person-circle"></i>
                </button>
                
                <ul className="dropdown-menu dropdown-menu-end">
                    <li><button className="dropdown-item" type="button" onClick={() => changeTheme()}>
                    {theme === 'dark' ? (
                        <>
                            <i className="bi bi-sun"></i> Tema claro
                        </>
                    ) : (
                        <>
                            <i className="bi bi-moon"></i> Tema oscuro
                        </>
                    )}
                    </button></li>
                    <li><button className="dropdown-item" type="button" onClick={() => beforeView()}><i className="bi bi-logout"></i> Cerrar sesión</button></li>
                </ul>
            </div>
        </header>
    )
}

export { Header };
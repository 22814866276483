import { DLGetSettings } from '../DL/Settings'

/**
 * Función para obtener las configuraciones de la plataforma
 * @param {string} language 
 * @param {boolean} isLoginPage 
 * @returns 
 */
const GetSettings = async (language = null, isLoginPage = false) => {
    try {
      const settings = await DLGetSettings(language, isLoginPage) // Esperar a que getSettings termine

      if (settings === null) {
        return null
      }
      
      return settings
    } catch (error) {
      console.log(error)
    }
}





const GetMenu = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.menu === undefined || settings.menu === null)) {
      return null
    }

    return settings.menu
  } catch (error) {
    console.log(error)
  }
}





const GetMessages = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.messages === undefined || settings.messages === null)) {
      return null
    }

    return settings.messages
  } catch (error) {
    console.log(error)
  }
}




/**
 * Función que obtiene el tema de las configuraciones del usuario
 * @returns array
 */
const GetTheme = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.theme === undefined || settings.theme === null)) {
      return null
    }

    return settings.theme
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que establece el nuevo tema en las configuraciones del usuario
 * @param {string} $theme 
 * @returns boolean
 */
const SetTheme = ($theme) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.theme === undefined || settings.theme === null)) {
      return null
    }

    if ($theme === null || $theme === '' || ($theme.toLowerCase() !== 'dark' && $theme.toLowerCase() !== 'light')) {
      return false
    }

    settings.theme = $theme.toLowerCase()
    sessionStorage.setItem('settings',JSON.stringify(settings))
    return true
  } catch (error) {
    console.log(error)
  }
}




/**
 * Función que obtiene la info del examen Demo
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns Array || null
 */
const GetDemoExam = (typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.psychosocial.demo === undefined || settings.exams.psychosocial.demo === null)) {
        return null
      }
      return settings.exams.psychosocial.demo
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.academic.demo === undefined || settings.exams.academic.demo === null)) {
        return null
      }
      return settings.exams.academic.demo
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.academic.demo === undefined || settings.exams.academic.demo === null)) {
        return null
      }
      return settings.exams.academic.demo
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

// if (typeOfExam === 'A') {
    
// } else if (typeOfExam === 'P') {

// } else if (typeOfExam === 'V') {

// } else {
//   return null
// }

/**
 * Función que obtiene la info del examen Demo en caso de ya haberse finalizado
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns Array || null
 */
const GetOptionFinishedOfDemoExam = (typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    let exam

    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.academic.demo === undefined || settings.exams.academic.demo === null) || (settings.exams.academic.demo.finished === undefined || settings.exams.academic.demo.finished === null)) {
        return null
      }
      exam = settings.exams.academic
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.psychosocial.demo === undefined || settings.exams.psychosocial.demo === null) || (settings.exams.psychosocial.demo.finished === undefined || settings.exams.psychosocial.demo.finished === null)) {
        return null
      }
      exam = settings.exams.psychosocial
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.vocational.demo === undefined || settings.exams.vocational.demo === null) || (settings.exams.vocational.demo.finished === undefined || settings.exams.vocational.demo.finished === null)) {
        return null
      }
      exam = settings.exams.vocational
    } else {
      return null
    }

    const Data = {
      "title" : exam.title,
      "name" : exam.demo.name,
      "finished" : exam.demo.finished,
      "dueDate" : exam.demo.dueDate,
      "completionDate" : exam.demo.completionDate
    }

    return Data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que establece el examen Demo como finalizado
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns boolean || null
 */
const SetFinishedDemoExam = (typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.academic.demo === undefined || settings.exams.academic.demo === null)) {
        return null
      }
      settings.exams.academic.demo.finished = true
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.psychosocial.demo === undefined || settings.exams.psychosocial.demo === null)) {
        return null
      }
      settings.exams.psychosocial.demo.finished = true
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.vocational.demo === undefined || settings.exams.vocational.demo === null)) {
        return null
      }
      settings.exams.vocational.demo.finished = true
    } else {
      return null
    }

    //Agregar fecha de completado
    sessionStorage.setItem('settings',JSON.stringify(settings))

    return true
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que obtiene la info del examen Real
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns Array || null
 */
const GetExam = (typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.academic.exam === undefined || settings.exams.academic.exam === null)) {
        return null
      }
      return settings.exams.academic.exam
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.psychosocial.exam === undefined || settings.exams.psychosocial.exam === null)) {
        return null
      }
      return settings.exams.psychosocial.exam
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.vocational.exam === undefined || settings.exams.vocational.exam === null)) {
        return null
      }
      return settings.exams.vocational.exam
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que obtiene la info del examen Real en caso de ya haberse finalizado
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns Array || null
 */
const GetOptionFinishedOfExam = (typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    let exam

    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.academic.exam === undefined || settings.exams.academic.exam === null) || (settings.exams.academic.exam.finished === undefined || settings.exams.academic.exam.finished === null)) {
        return null
      }
      exam = settings.exams.academic.exam
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.psychosocial.exam === undefined || settings.exams.psychosocial.exam === null) || (settings.exams.psychosocial.exam.finished === undefined || settings.exams.psychosocial.exam.finished === null)) {
        return null
      }
      exam = settings.exams.psychosocial.exam
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.vocational.exam === undefined || settings.exams.vocational.exam === null) || (settings.exams.vocational.exam.finished === undefined || settings.exams.vocational.exam.finished === null)) {
        return null
      }
      exam = settings.exams.vocational.exam
    } else {
      return null
    }

    const Data = {
      "name" : exam.name,
      "finished" : exam.finished,
      "dueDate" : exam.dueDate,
      "completionDate" : exam.completionDate
    }

    return Data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que establece el examen Real como finalizado
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns boolean || null
 */
const SetFinishedExam = (typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.academic.exam === undefined || settings.exams.academic.exam === null)) {
        return null
      }
      settings.exams.academic.exam.finished = true
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.psychosocial.exam === undefined || settings.exams.psychosocial.exam === null)) {
        return null
      }
      settings.exams.psychosocial.exam.finished = true
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.vocational.exam === undefined || settings.exams.vocational.exam === null)) {
        return null
      }
      settings.exams.vocational.exam.finished = true
    } else {
      return null
    }

    //Agregar fecha de completado
    sessionStorage.setItem('settings',JSON.stringify(settings))

    return true
  } catch (error) {
    console.log(error)
  }
}





//Evaluator - Timer
/**
 * Función que devuelve una cadena con el número de segundos que le quedan del examen Real
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns string
 */
const GetExamTime = (typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    let exam
    
    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.academic.exam === undefined || settings.exams.academic.exam === null) || (settings.exams.academic.exam.time === undefined || settings.exams.academic.exam.time === null)) {
        return null
      }
      exam = settings.exams.academic.exam
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.psychosocial.exam === undefined || settings.exams.psychosocial.exam === null) || (settings.exams.psychosocial.exam.time === undefined || settings.exams.psychosocial.exam.time === null)) {
        return null
      }
      exam = settings.exams.psychosocial.exam
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.vocational.exam === undefined || settings.exams.vocational.exam === null) || (settings.exams.vocational.exam.time === undefined || settings.exams.vocational.exam.time === null)) {
        return null
      }
      exam = settings.exams.vocational.exam
    } else {
      return null
    }

    return exam.time
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que devuelve una cadena con el número de segundos que le quedan del examen Demo
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns string
 */
const GetDemoTime = (typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    let exam

    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.academic.demo === undefined || settings.exams.academic.demo === null) || (settings.exams.academic.demo.time === undefined || settings.exams.academic.demo.time === null)) {
        return null
      }
      exam = settings.exams.academic.demo
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.psychosocial.demo === undefined || settings.exams.psychosocial.demo === null) || (settings.exams.psychosocial.demo.time === undefined || settings.exams.psychosocial.demo.time === null)) {
        return null
      }
      exam = settings.exams.psychosocial.demo
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.vocational.demo === undefined || settings.exams.vocational.demo === null) || (settings.exams.vocational.demo.time === undefined || settings.exams.vocational.demo.time === null)) {
        return null
      }
      exam = settings.exams.vocational.demo
    } else {
      return null
    }

    return exam.time
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que establece y guarda el número de segundos que le quedan del examen Real
 * @param {Date} currentTime
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns string
 */
const SetRemainingExamTime = (currentTime, typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.academic.exam === undefined || settings.exams.academic.exam === null)) {
        return null
      }
      settings.exams.academic.exam.time = String(currentTime)
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.psychosocial.exam === undefined || settings.exams.psychosocial.exam === null)) {
        return null
      }
      settings.exams.psychosocial.exam.time = String(currentTime)
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.vocational.exam === undefined || settings.exams.vocational.exam === null)) {
        return null
      }
      settings.exams.vocational.exam.time = String(currentTime)
    } else {
      return null
    }

    //Actualizar tiempo del examen
    //settings.exam.time = String(currentTime)
    sessionStorage.setItem('settings',JSON.stringify(settings))

    return true
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que establece y guarda el número de segundos que le quedan del examen Demo
 * @param {Date} currentTime
 * @param {string} typeOfExam A = Academic, P = Psychosocial o V = Vocational
 * @returns string
 */
const SetRemainingDemoTime = (currentTime, typeOfExam) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (typeOfExam.toUpperCase() === 'A') {
      if (settings === null || (settings.exams.academic.demo === undefined || settings.exams.academic.demo === null)) {
        return null
      }
      settings.exams.academic.demo.time = String(currentTime)
    } else if (typeOfExam.toUpperCase() === 'P') {
      if (settings === null || (settings.exams.psychosocial.demo === undefined || settings.exams.psychosocial.demo === null)) {
        return null
      }
      settings.exams.psychosocial.demo.time = String(currentTime)
    } else if (typeOfExam.toUpperCase() === 'V') {
      if (settings === null || (settings.exams.vocational.demo === undefined || settings.exams.vocational.demo === null)) {
        return null
      }
      settings.exams.vocational.demo.time = String(currentTime)
    } else {
      return null
    }

    //Actualizar tiempo del examen
    //settings.demo.time = String(currentTime)
    sessionStorage.setItem('settings',JSON.stringify(settings))

    return true
  } catch (error) {
    console.log(error)
  }
}

export { GetSettings, GetMenu, GetMessages, GetTheme, SetTheme, GetDemoExam, GetOptionFinishedOfDemoExam, SetFinishedDemoExam, GetExam, GetOptionFinishedOfExam, SetFinishedExam, GetExamTime, GetDemoTime, SetRemainingExamTime, SetRemainingDemoTime };
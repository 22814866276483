import { React, useState, useRef, useEffect } from 'react'
// import { Helmet } from 'react-helmet-async'

//Funciones personalizadas
import { GetSettings } from './../../utilities/BL/Settings' //General y temporal

//Funciones comunes
//import { ValidateForm } from './../../commons/components/BL/Logins'
import { ValidateForm } from '../../utilities/BL/Login'

//Componentes comunes
import { Input } from './../../commons/components/Login/Fields/Input'
import { Checkbox } from './../../commons/components/Login/Fields/Checkbox'
import { Button } from './../../commons/components/Common/Button'



function Login({ nextView }) {
  //Estados
  const [formData, setFormData] = useState({user: '', password: '', remember: false}) // Estado que maneja la info del formulario
  const [errors, setErrors] = useState({}) // Estado que maneja los errores
  const [isSubmitted, setIsSubmitted] = useState(false) // Estado que controla si se ha enviado, o no, el formulario
  const [settings, setSettings] = useState({}) // Estado para almacenar la configuración de la página
  const [isLoading, setIsLoading] = useState(true) // Estado qude carga
  const [isVisible, setIsVisible] = useState('') // Estado qude carga

  //Objetos que almacenan los controles del formulario
  const userRef = useRef(null)
  const passwordRef = useRef(null)



  //--- Funciones personalizadas ---//
  /**
   * Función que obtiene las configuraciones de la página para poder cambiar el idioma de la página
   * @param {string} language 
   * @param {boolean} isLoginPage 
   */
  const LoadPage = async (language = null, isLoginPage = false) => {
    try {
      const settings = await GetSettings(language, isLoginPage) // Esperar a que getSettings termine

      if (settings !== null) {
        setSettings(settings) // Actualizar el estado con la nueva configuración
      }

      //Establecer el tema claro a la web
      document.body.classList.remove('DarkTheme')
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false) // Marcar la carga como finalizada
    }
  }

  const Login = (e) => {
    try {
      e.preventDefault()
      setIsSubmitted(true) // Marcar el formulario como enviado

      const Response = ValidateForm(formData) // Validación detallada de los campos del formulario
      setErrors(Response) // Actualizar el estado de errores con los errores encontrados
      
      // Si no hay errores, ejecutar función
      if (Object.keys(Response).length === 0) {
        //setIsVisible('d-none')
        nextView()
      } else {
        // Hacer focus sobre el campo con info incorrecta
        if (Response.user) {
          userRef.current.focus()
        } else if (Response.password) {
          passwordRef.current.focus()
        }
      }
    } catch (error) {
        console.log(error)
    }
  }
  //--- Funciones personalizadas ---//



  //--- Eventos de la página web ---//
  /**
   * Función para obtener la configuración, una vez que termine de cargarse el componente
   */
  useEffect(() => {
    // Cargar configuración al montar el componente (carga inicial)
    LoadPage(null, true)
  },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar
  //--- Eventos de la página web ---//



  //--- Eventos de formulario ---//
  /**
   * Función que se ejecuta cuando se realiza un cambio en el checkbox del formulario.
   * Obtiene la info de los campos del formulario y actualiza el valor del checkbox (True o False)
   * @param {Event} e 
   */
  const InputChange = (e) => {
    try {  
      const { id, value, type, checked } = e.target
      
      setFormData({
        ...formData,
        [id]: type === 'checkbox' ? checked : value
      })
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Función que se ejecuta cuando se presiona la tecla Enter o se presiona el botón del formulario
   * @param {Event} e 
   */
  const FormSubmit = (e) => {
    try {
      Login(e)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Función que se ejecuta al presiona la tecla Enter en el formulario
   * @param {Event} e 
   */
  const EnterKeyPressInForm = (e) => {
    try {
      if (e.key === 'Enter') {
        Login(e)
      }
    } catch (error) {
      console.log(error)
    }
  }
  //--- Eventos de formulario ---//
  
  
  
  return (
    <>
      {/* <Helmet>
        <title>Evalution</title> {/ Cambiar el título /}
      </Helmet> */}

      <main className='container'>
        {isLoading ? (
          <div>Cargando...</div> // Mostrar un mensaje de carga mientras se obtienen los datos
        ) : (
          <form className={`loginForm mt-5 ${isVisible}`} onSubmit={FormSubmit} onKeyPress={EnterKeyPressInForm}>
            <p id='formTitle' className='loginForm-Title'>{settings.login.title}</p>

              <Input
                type={'text'}
                id={'user'}
                value={formData.user}
                onChange={InputChange}
                placeholder={settings.login.fieldUser}
                error={isSubmitted ? errors.user : null} // Mostrar error solo si se ha enviado el formulario
                isSubmitted={isSubmitted} // Pasar isSubmitted al Input
                ref={userRef}
              />

              <Input
                type={'password'} 
                id={'password'}
                value={formData.password}
                onChange={InputChange}
                placeholder={settings.login.fieldPassword}
                error={isSubmitted ? errors.password : null} // Mostrar error solo si se ha enviado el formulario
                isSubmitted={isSubmitted} // Pasar isSubmitted al Input
                ref={passwordRef}
              />

              <Checkbox id='remember' checked={formData.remember} onChange={InputChange} label={settings.login.fieldCheck} />

              <Button type={'submit'} className={'loginForm-Button'} text={settings.login.button} />
          </form>
        )}
      </main>
    </>
  )
}

export { Login };